body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
  /* "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  /* sans-serif; */
  font-family: "metrischmedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Metrisch-light";
  src: url("../public/fonts/Metrisch-Light-webfont.eot");
  src: url("../public/fonts/Metrisch-Light-webfont.woff") format("woff"),
    /* Modern Browsers */ url("../public/fonts/Metrisch-Light-webfont.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "metrischmedium";
  src: url("../public/fonts/Metrisch-Medium-webfont.eot");
  src: url("../public/fonts/Metrisch-Medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Metrisch-Medium-webfont.woff2") format("woff2"),
    url("../public/fonts/Metrisch-Medium-webfont.woff") format("woff"),
    url("../public/fonts/Metrisch-Medium-webfont.ttf") format("truetype"),
    url("../public/fonts/Metrisch-Medium-webfont.svg#metrischmedium")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "metrischbook";
  src: url("../public/fonts/Metrisch-Book-webfont.eot");
  src: url("../public/fonts/Metrisch-Book-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Metrisch-Book-webfont.woff2") format("woff2"),
    url("../public/fonts/Metrisch-Book-webfont.woff") format("woff"),
    url("../public/fonts/Metrisch-Book-webfont.ttf") format("truetype"),
    url("../public/fonts/Metrisch-Book-webfont.svg#metrischbook") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "amithenregular";
  src: url("../public/fonts/amithen-webfont.eot");
  src: url("../public/fonts/amithen-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/amithen-webfont.woff2") format("woff2"),
    url("../public/fonts/amithen-webfont.woff") format("woff"),
    url("../public/fonts/amithen-webfont.svg#amithenregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "metrischbold";
  src: url("../public/fonts/Metrisch-Bold-webfont.eot");
  src: url("../public/fonts/Metrisch-Bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Metrisch-Bold-webfont.woff2") format("woff2"),
    url("../public/fonts/Metrisch-Bold-webfont.woff") format("woff"),
    url("../public/fonts/Metrisch-Bold-webfont.ttf") format("truetype"),
    url("../public/fonts/Metrisch-Bold-webfont.svg#metrischbold") format("svg");
  font-weight: normal;
  font-style: normal;
}
